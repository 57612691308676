import React, { useState } from "react";
import { TEXT_DEF } from "../../../constant/text";
import { Button, InputNumber, Switch } from "antd";
import ModalCommon from "../../../components/Modal";
import API, { ADMIN_HILO } from "../../../network/api";
import { RIGHTS } from "../../../constant/rights";
import { hasRight } from "../../../components/until/right";

const HiloSetting = ({
  data,
  auth,
  roleArr,
  reload
}) => {
  const [isMaintenance, setIsMaintenance] = useState()
  const [valueIp1, setValueIp1] = useState();
  const [valueIp2, setValueIp2] = useState();
  const [valueIp3, setValueIp3] = useState();
  const [valueIp4, setValueIp4] = useState();
  const [valueIp5, setValueIp5] = useState();
  const [valueIp6_1, setValueIp6_1] = useState();
  const [valueIp6_2, setValueIp6_2] = useState();
  const [valueIp7_1, setValueIp7_1] = useState();
  const [valueIp7_2, setValueIp7_2] = useState();
  const [valueIp8_1, setValueIp8_1] = useState();
  const [valueIp8_2, setValueIp8_2] = useState();
  const [valueIp9_1, setValueIp9_1] = useState();
  const [valueIp9_2, setValueIp9_2] = useState();
  const [valueIp10_1, setValueIp10_1] = useState();
  const [valueIp10_2, setValueIp10_2] = useState();
  const [valueIp11_1, setValueIp11_1] = useState();
  const [valueIp11_2, setValueIp11_2] = useState();
  const [valueIp12_1, setValueIp12_1] = useState();
  const [valueIp12_2, setValueIp12_2] = useState();
  const [valueIp13_1, setValueIp13_1] = useState();
  const [valueIp13_2, setValueIp13_2] = useState();
  const [valueIp14_1, setValueIp14_1] = useState();
  const [valueIp14_2, setValueIp14_2] = useState();
  const [valueIp15_1, setValueIp15_1] = useState();
  const [valueIp15_2, setValueIp15_2] = useState();
  const [valueIp16_1, setValueIp16_1] = useState();
  const [valueIp16_2, setValueIp16_2] = useState();
  const [valueIp17_1, setValueIp17_1] = useState();
  const [valueIp17_2, setValueIp17_2] = useState();
  const [valueIp18_1, setValueIp18_1] = useState();
  const [valueIp18_2, setValueIp18_2] = useState();
  const [valueIp19, setValueIp19] = useState();

  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);

  const showConfirm = (key) => {
    if(valueIp1 <= 0 || valueIp2 <= 0 || valueIp3 <= 0 || valueIp5 <= 0 ||
      valueIp6_1 <= 0 || valueIp7_1 <= 0 || valueIp8_1 <= 0 || valueIp9_1 <= 0 ||
      valueIp10_1 <= 0 || valueIp11_1 <= 0 || valueIp12_1 <= 0 || valueIp13_1 <= 0 ||
      valueIp14_1 <= 0 || valueIp15_1 <= 0 || valueIp16_1 <= 0 || valueIp17_1 <= 0 || 
      valueIp18_1 <= 0 || valueIp6_2 <= 0 || valueIp7_2 <= 0 || valueIp8_2 <= 0 || valueIp9_2 <= 0 ||
      valueIp10_2 <= 0 || valueIp11_2 <= 0 || valueIp12_2 <= 0 || valueIp13_2 <= 0 ||
      valueIp14_2 <= 0 || valueIp15_2 <= 0 || valueIp16_2 <= 0 || valueIp17_2 <= 0 || 
      valueIp18_2 <= 0 || valueIp19 <= 0
    ) {
      alert('Giá trị không được nhỏ hơn 0');
      return
    }

    let params;
    const id = data?.find(x => x.key == key)?.id;
    switch (key) {
      case "isMaintenance":
        params = {
          key: "isMaintenance",
          value: isMaintenance.toString()
        }
        break;
      case "firstLose":
        params = {
          key: "firstLose",
          value: valueIp1.toString()
        }
        break;
      case "ratioRev":
        params = {
          key: "ratioRev",
          value: valueIp2.toString()
        }
        break;
      case "ratioBonus":
        params = {
          key: "ratioBonus",
          value: valueIp3.toString()
        }
        break;
      case "multiDefault":
        params = {
          key: "multiDefault",
          value: valueIp4.toString()
        }
        break;
      case "maxSkip":
        params = {
          key: "maxSkip",
          value: valueIp5.toString()
        }
        break;
      case "rateMulti_K":
        params = {
          key: "rateMulti_K",
          value: `${valueIp6_1.toString()}_${valueIp6_2.toString()}`
        }
        break;
      case "rateMulti_Q":
        params = {
          key: "rateMulti_Q",
          value: `${valueIp7_1.toString()}_${valueIp7_2.toString()}`
        }
        break;
      case "rateMulti_J":
        params = {
          key: "rateMulti_J",
          value: `${valueIp8_1.toString()}_${valueIp8_2.toString()}`
        }
        break;
      case "rateMulti_10":
        params = {
          key: "rateMulti_10",
          value: `${valueIp9_1.toString()}_${valueIp9_2.toString()}`
        }
        break;
      case "rateMulti_9":
        params = {
          key: "rateMulti_9",
          value: `${valueIp10_1.toString()}_${valueIp10_2.toString()}`
        }
        break;
      case "rateMulti_8":
        params = {
          key: "rateMulti_8",
          value: `${valueIp11_1.toString()}_${valueIp11_2.toString()}`
        }
        break;
      case "rateMulti_7":
        params = {
          key: "rateMulti_7",
          value: `${valueIp12_1.toString()}_${valueIp12_2.toString()}`
        }
        break;
      case "rateMulti_6":
        params = {
          key: "rateMulti_6",
          value: `${valueIp13_1.toString()}_${valueIp13_2.toString()}`
        }
        break;
      case "rateMulti_5":
        params = {
          key: "rateMulti_5",
          value: `${valueIp14_1.toString()}_${valueIp14_2.toString()}`
        }
        break;
      case "rateMulti_4":
        params = {
          key: "rateMulti_4",
          value: `${valueIp15_1.toString()}_${valueIp15_2.toString()}`
        }
        break;
      case "rateMulti_3":
        params = {
          key: "rateMulti_3",
          value: `${valueIp16_1.toString()}_${valueIp16_2.toString()}`
        }
        break;
      case "rateMulti_2":
        params = {
          key: "rateMulti_2",
          value: `${valueIp17_1.toString()}_${valueIp17_2.toString()}`
        }
        break;
      case "rateMulti_A":
        params = {
          key: "rateMulti_A",
          value: `${valueIp18_1.toString()}_${valueIp18_2.toString()}`
        }
        break;
      case "ratioWin":
        params = {
          key: "ratioWin",
          value: valueIp19.toString()
        }
        break;
      default: break;
    }
    const dataReq = {
      id: id,
      params: params
    }
    setDataUp(dataReq);
    setIsShowModalConfirm(true)
  }

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const onRequest = async () => {
    // console.log(rateMinWin)
    if (!dataUp) return;
    await API.patch(`${ADMIN_HILO}/updateConfig/${dataUp?.id}`, dataUp?.params)
      .then(response => {
        const result = response.data?.data;
        if (result?.code === 20004) {
          reload();
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      }).catch((error) => {
        console.log(error);
      })
  }

  const renderIp = (label, defaultValue, onChangeIp, onUpdate, isHilo, onChangeIp2) => {
    let dataHilo;
    if (isHilo) {
      const dataArr = defaultValue?.split("_");
      dataHilo = {
        value1: Number(dataArr?.[0]),
        value2: Number(dataArr?.[1])
      }
    }
    return (
      <div className="col-12 flex">
        <div className="col-3 text-left">{label}</div>
        <div className="col-6">
          {isHilo ?
            <div className="col-12 flex" style={{ alignItems: 'center' }}>
              <div className="col-2 text-left">Cao: </div>
              <div className="col-3">
                <InputNumber
                  style={{ width: '90%' }}
                  // addonAfter="%" 
                  // min={10} max={100} 
                  min={0}
                  onChange={onChangeIp}
                  defaultValue={dataHilo?.value1}
                />
              </div>
              <div className="col-2 text-left ml-6">Thấp: </div>
              <div className="col-3">
                <InputNumber
                  style={{ width: '90%' }}
                  // addonAfter="%" 
                  // min={10} max={100} 
                  min={0}
                  onChange={onChangeIp2}
                  defaultValue={dataHilo?.value2}
                />
              </div>
            </div>
            :
            <InputNumber
              style={{ width: '90%' }}
              // addonAfter="%" 
              // min={10} max={100} 
              min={0}
              onChange={onChangeIp}
              defaultValue={Number(defaultValue)}
            />
          }

        </div>
        {
          hasRight([RIGHTS.EditSettingOriginals]) ? (
            <div className="col-3 text-left">
              <Button
                type="primary"
                onClick={onUpdate}
              >
                {TEXT_DEF.text0016}
              </Button>
            </div>
          ) : null
        }
      </div>
    )
  }

  return (
    <>
    { data ? 
      <div className="col-12 flex-col"
        style={{ display: "flex", marginTop: 30, alignItems: "center" }}
      >
        <div className="col-12 flex">
          <div className="col-3 text-left">{TEXT_DEF.text0065}</div>
          <div className="col-6">
            <Switch
              checkedChildren="Bật"
              unCheckedChildren="Tắt"
              defaultChecked={data?.find(x => x.key == "isMaintenance")?.value === "true" ? true : false}
              style={{ width: 60, marginTop: "5px" }}
              onChange={(e) => handleChangeSwitch(e)}
            />
          </div>
          {
            hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button
                  type="primary"
                  onClick={() => showConfirm("isMaintenance")}
                >
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null
          }
        </div>
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Thắng lần đầu (đơn vị 1 = 1000 VND)", data?.find(x => x.key == "firstLose")?.value, (e) => setValueIp1(e), () => showConfirm("firstLose"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Tỉ lệ thắng", data?.find(x => x.key == "ratioWin")?.value, (e) => setValueIp19(e), () => showConfirm("ratioWin"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Tỉ lệ rev", data?.find(x => x.key == "ratioRev")?.value, (e) => setValueIp2(e), () => showConfirm("ratioRev"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Tỉ lệ bonus", data?.find(x => x.key == "ratioBonus")?.value, (e) => setValueIp3(e), () => showConfirm("ratioBonus"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Tỉ lệ mặc định lượt đầu", data?.find(x => x.key == "multiDefault")?.value, (e) => setValueIp4(e), () => showConfirm("multiDefault"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Giới hạn bỏ qua", data?.find(x => x.key == "maxSkip")?.value, (e) => setValueIp5(e), () => showConfirm("maxSkip"), false)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số HiLo_K", data?.find(x => x.key == "rateMulti_K")?.value, (e) => setValueIp6_1(e), () => showConfirm("rateMulti_K"), true, (e) => setValueIp6_2(e),)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số HiLo_Q", data?.find(x => x.key == "rateMulti_Q")?.value, (e) => setValueIp7_1(e), () => showConfirm("rateMulti_Q"), true, (e) => setValueIp7_2(e),)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số HiLo_J", data?.find(x => x.key == "rateMulti_J")?.value, (e) => setValueIp8_1(e), () => showConfirm("rateMulti_J"), true, (e) => setValueIp8_2(e),)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số HiLo_10", data?.find(x => x.key == "rateMulti_10")?.value, (e) => setValueIp9_1(e), () => showConfirm("rateMulti_10"), true, (e) => setValueIp9_2(e),)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số HiLo_9", data?.find(x => x.key == "rateMulti_9")?.value, (e) => setValueIp10_1(e), () => showConfirm("rateMulti_9"), true, (e) => setValueIp10_2(e),)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số HiLo_8", data?.find(x => x.key == "rateMulti_8")?.value, (e) => setValueIp11_1(e), () => showConfirm("rateMulti_8"), true, (e) => setValueIp11_2(e),)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số HiLo_7", data?.find(x => x.key == "rateMulti_7")?.value, (e) => setValueIp12_1(e), () => showConfirm("rateMulti_7"), true, (e) => setValueIp12_2(e),)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số HiLo_6", data?.find(x => x.key == "rateMulti_6")?.value, (e) => setValueIp13_1(e), () => showConfirm("rateMulti_6"), true, (e) => setValueIp13_2(e),)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số HiLo_5", data?.find(x => x.key == "rateMulti_5")?.value, (e) => setValueIp14_1(e), () => showConfirm("rateMulti_5"), true, (e) => setValueIp14_2(e),)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số HiLo_4", data?.find(x => x.key == "rateMulti_4")?.value, (e) => setValueIp15_1(e), () => showConfirm("rateMulti_4"), true, (e) => setValueIp15_2(e),)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số HiLo_3", data?.find(x => x.key == "rateMulti_3")?.value, (e) => setValueIp16_1(e), () => showConfirm("rateMulti_3"), true, (e) => setValueIp16_2(e),)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số HiLo_2", data?.find(x => x.key == "rateMulti_2")?.value, (e) => setValueIp17_1(e), () => showConfirm("rateMulti_2"), true, (e) => setValueIp17_2(e),)}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số HiLo_A", data?.find(x => x.key == "rateMulti_A")?.value, (e) => setValueIp18_1(e), () => showConfirm("rateMulti_A"), true, (e) => setValueIp18_2(e),)}
        <div style={{ margin: "10px 0px" }} />
      </div> : null }
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0071}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0072}
          actionNext={onRequest}
          close={() => { setIsShowModalConfirm(false); setDataUp(undefined) }}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0073}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </>
  )
}

export default HiloSetting;