import React, { useState, useEffect, useCallback } from "react";
import { TEXT_DEF } from "../../../constant/text";
import { Button, Input, Table, InputNumber, Switch } from "antd";
import ModalCommon from "../../../components/Modal";
import API, { ADMIN_POKER } from "../../../network/api";
import { RIGHTS } from "../../../constant/rights";
import { hasRight } from "../../../components/until/right";

const PokerSetting = ({
  data,
  auth,
  roleArr,
  reload
}) => {
  const [isMaintenance, setIsMaintenance] = useState()
  const [valueIp1, setValueIp1] = useState();
  const [valueIp2, setValueIp2] = useState();
  const [valueIp3, setValueIp3] = useState();
  const [valueIp4, setValueIp4] = useState();
  const [valueIp5, setValueIp5] = useState();
  const [valueIp6, setValueIp6] = useState();
  const [valueIp7, setValueIp7] = useState();
  const [valueIp8, setValueIp8] = useState();
  const [valueIp9, setValueIp9] = useState();
  const [valueIp10, setValueIp10] = useState();
  const [valueIp11, setValueIp11] = useState();
  const [dataUp, setDataUp] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);

  const showConfirm = (key) => {
    if(valueIp1 <= 0 || valueIp2 <= 0 || valueIp3 <= 0 || valueIp5 <= 0 ||
      valueIp6 <= 0 || valueIp7 <= 0 || valueIp8 <= 0 || valueIp9 <= 0 ||
      valueIp10 <= 0 || valueIp11 <=0
    ) {
      alert('Giá trị không được nhỏ hơn 0');
      return
    }
    let params;
    const id = data?.find(x => x.key == key)?.id;
    switch (key) {
      case "isMaintenance":
        params = {
          key: "isMaintenance",
          value: isMaintenance.toString()
        }
        break;
      case "firstLose":
        params = {
          key: "firstLose",
          value: valueIp1.toString()
        }
        break;
      case "ratioRev":
        params = {
          key: "ratioRev",
          value: valueIp2.toString()
        }
        break;
      case "ratioBonus":
        params = {
          key: "ratioBonus",
          value: valueIp3.toString()
        }
        break;
      case "reward1Doi":
        params = {
          key: "reward1Doi",
          value: valueIp4.toString()
        }
        break;
      case "reward2Doi":
        params = {
          key: "reward2Doi",
          value: valueIp5.toString()
        }
        break;
      case "rewardXamCo":
        params = {
          key: "rewardXamCo",
          value: valueIp6.toString()
        }
        break;
      case "rewardSanh":
        params = {
          key: "rewardSanh",
          value: valueIp7.toString()
        }
        break;
      case "rewardThung":
        params = {
          key: "rewardThung",
          value: valueIp8.toString()
        }
        break;
      case "rewardCuLu":
        params = {
          key: "rewardCuLu",
          value: valueIp9.toString()
        }
        break;
      case "rewardTuQuy":
        params = {
          key: "rewardTuQuy",
          value: valueIp10.toString()
        }
        break;
      case "rewardThungPhaSanh":
        params = {
          key: "rewardThungPhaSanh",
          value: valueIp11.toString()
        }
        break;
      default: break;
    }
    const dataReq = {
      id: id,
      params: params
		}
		console.log('dataReq: ', dataReq)
    setDataUp(dataReq);
    setIsShowModalConfirm(true)
  }

  const onRequest = async () => {
    // console.log(rateMinWin)
    if (!dataUp) return;
    await API.patch(`${ADMIN_POKER}/updateConfig/${dataUp?.id}`, dataUp?.params)
      .then(response => {
        const result = response.data?.data;
        if (result?.code === 20004) {
          reload();
          setIsShowModalConfirm(false);
          setIsShowModalSuccess(true);
        }
      }).catch((error) => {
        console.log(error);
      })
  }

  const handleChangeSwitch = (e) => {
    setIsMaintenance(e);
  };

  const renderIp = (label, defaultValue, onChangeIp, onUpdate) => {
    return (
      <div className="col-12 flex">
        <div className="col-3 text-left">{label}</div>
        <div className="col-6">
          <InputNumber
            style={{ width: '90%' }}
            // addonAfter="%" 
            // min={10} max={100} 
            min={0}
            onChange={onChangeIp}
            defaultValue={Number(defaultValue)}
          />
        </div>
        {
          hasRight([RIGHTS.EditSettingOriginals]) ? (
            <div className="col-3 text-left">
              <Button
                type="primary"
                onClick={onUpdate}
              >
                {TEXT_DEF.text0016}
              </Button>
            </div>
          ) : null
        }
      </div>
    )
  }

  return (
    <>
    {
      data ? 
    
      <div className="col-12 flex-col"
        style={{ display: "flex", marginTop: 30, alignItems: "center" }}
      >
        <div className="col-12 flex">
          <div className="col-3 text-left">{TEXT_DEF.text0065}</div>
          <div className="col-6">
            <Switch
              checkedChildren="Bật"
              unCheckedChildren="Tắt"
              defaultChecked={data?.find(x => x.key == "isMaintenance")?.value === "true" ? true : false}
              style={{ width: 60, marginTop: "5px" }}
              onChange={(e) => handleChangeSwitch(e)}
            />
          </div>
          {
            hasRight([RIGHTS.EditSettingOriginals]) ? (
              <div className="col-3 text-left">
                <Button
                  type="primary"
                  onClick={() => showConfirm("isMaintenance")}
                >
                  {TEXT_DEF.text0016}
                </Button>
              </div>
            ) : null
          }
        </div>
        
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Thắng lần đầu (đơn vị 1 = 1000 VND)", data?.find(x => x.key == "firstLose")?.value, (e) => setValueIp1(e), () => showConfirm("firstLose"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Tỉ lệ rev", data?.find(x => x.key == "ratioRev")?.value, (e) => setValueIp2(e), () => showConfirm("ratioRev"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Tỉ lệ bonus", data?.find(x => x.key == "ratioBonus")?.value, (e) => setValueIp3(e), () => showConfirm("ratioBonus"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng 1 đôi", data?.find(x => x.key == "reward1Doi")?.value, (e) => setValueIp4(e), () => showConfirm("reward1Doi"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng 2 đôi", data?.find(x => x.key == "reward2Doi")?.value, (e) => setValueIp5(e), () => showConfirm("reward2Doi"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng xám cô", data?.find(x => x.key == "rewardXamCo")?.value, (e) => setValueIp6(e), () => showConfirm("rewardXamCo"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng sảnh", data?.find(x => x.key == "rewardSanh")?.value, (e) => setValueIp7(e), () => showConfirm("rewardSanh"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng thùng", data?.find(x => x.key == "rewardThung")?.value, (e) => setValueIp8(e), () => showConfirm("rewardThung"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng cù lũ", data?.find(x => x.key == "rewardCuLu")?.value, (e) => setValueIp9(e), () => showConfirm("rewardCuLu"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng tứ quý", data?.find(x => x.key == "rewardTuQuy")?.value, (e) => setValueIp10(e), () => showConfirm("rewardTuQuy"))}
        <div style={{ margin: "10px 0px" }} />
        {renderIp("Hệ số thưởng thùng phá sảnh", data?.find(x => x.key == "rewardThungPhaSanh")?.value, (e) => setValueIp11(e), () => showConfirm("rewardThungPhaSanh"))}
      </div> : null}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0071}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0072}
          actionNext={onRequest}
          close={() => { setIsShowModalConfirm(false); setDataUp(undefined) }}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0073}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </>
  )
}

export default PokerSetting;